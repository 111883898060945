import React from 'react';
import SalesGoodsItem from '../SalesGoodsItem';
import { Wrapper, Content, Line, MLabel, SHeader } from './styles';
import Common from 'Common';
import dayjs from 'dayjs';


const SiteDetails = ({ objSite, totalPrice, salesGoodsList }) => {
    return (
        <Wrapper>
            <Content>
                <Line>
                    <MLabel>現場：</MLabel>
                    {objSite.SiteName || '―'}
                </Line>
                <Line>
                    <MLabel>住所：</MLabel>
                    <a href={`https://www.google.co.jp/maps/place/${objSite.AddressPrefecture || ''}${objSite.AddressCity || ''}${objSite.AddressNumber || ''}${objSite.Building || ''}`}
                        target="_blank" rel="noopener noreferrer">
                        〒{objSite.PostalCode || '―'}　{objSite.AddressPrefecture || ''}{objSite.AddressCity || ''}{objSite.AddressNumber || ''}{objSite.Building || ''}
                    </a>
                </Line>
                <Line>
                    <MLabel>TEL：</MLabel>
                    {objSite.TEL || '―'}
                </Line>
                <Line>
                    <span ><MLabel>期間：</MLabel>{objSite.DateBeginning.length > 9 ? dayjs(objSite.DateBeginning).format("YYYY年MM月DD日") : '－'}</span>～
                    <span>{objSite.DateFinished.length > 9 ? dayjs(objSite.DateFinished).format("YYYY年MM月DD日") : '－'}</span>
                </Line>
                <Line>
                    <MLabel>面積：</MLabel>{objSite.Area || '―'}m<sup>2</sup>
                </Line>
                <Line>
                    <MLabel>メモ：</MLabel>{objSite.ClientMemo || '―'}
                </Line>
                {!!objSite.PriceVisible && (<Line>
                    <MLabel>合計金額：</MLabel>{Common.NumFormat(totalPrice) || '―'}
                </Line>)}

                <SHeader>商品一覧</SHeader>
                {salesGoodsList?.length > 0 && (<>
                    {salesGoodsList.map((item) => {
                        return <SalesGoodsItem key={item.SalesGoodsID} priceVisible={!!objSite.PriceVisible} item={item} />
                    })}
                </>)}
            </Content>
        </Wrapper>
    )
}

export default SiteDetails;