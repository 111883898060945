
import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useEffect } from "react";
import colorBookApi from "api/colorBookApi";
import colorNoApi from "api/colorNoApi";
import { MLabel } from "./styles";
import { COLOR_BOOK_OTHERID } from "Common";
import { FormControl, MenuItem, Select } from "@mui/material";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

const SelectColor = ({ colorBook, setColorBook, colorNo, setColorNo }) => {

  //色No
  const [inputColorNo, setInputColorNo] = useState('');
  const [loadingColorNo, setLoadingColorNo] = useState(false);


  //色見本帳取得
  useEffect(() => {
    (async function getColorBook() {
      try {
        const { data } = await colorBookApi.getAllColorBook();
        if (!data?.dtColorBook || data?.dtColorBook.length < 1) setColorBook(preValue => ({ ...preValue, options: [] }));
        setColorBook({
          selectedValue: data.dtColorBook[0],
          options: data.dtColorBook
        })
      } catch (error) {
        console.log(error);
        alert(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //色No
  useEffect(() => {
    (async function getColorNo() {
      const colorBookID = colorBook?.selectedValue?.ColorBookID || 0;
      if (!colorBookID) return;
      if (colorBookID === COLOR_BOOK_OTHERID) {
        setColorNo({
          selectedValue: {},
          options: []
        })
        return;
      }

      try {
        setLoadingColorNo(true);
        const params = {
          colorBookID
        }
        const { data } = await colorNoApi.getColorNo(params);
        if (!data?.dtColorNo || data?.dtColorNo.length < 1) setColorNo(preValue => ({ ...preValue, options: [] }));
        setColorNo({
          selectedValue: data.dtColorNo[0],
          options: data.dtColorNo
        })
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        setLoadingColorNo(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorBook]);

  return <>
    <div>
      <Stack direction={"row"} alignItems={"center"} sx={{ my: "2px" }} >
        <MLabel style={{ width: "65px" }}>色見本帳：</MLabel>
        {!!colorBook?.selectedValue && !!colorBook?.selectedValue.ColorBookID
          && !!colorBook?.options?.length && (
            <FormControl sx={{ width: 170, ml: 1 }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={colorBook?.selectedValue.ColorBookID}
                onChange={(e, value) => {
                  setColorBook(preValue => ({
                    ...preValue,
                    selectedValue: { ColorBookID: value.props.value, ColorBookName: value.props.children },
                  }));
                }}
                MenuProps={MenuProps}

              >
                {[...colorBook.options].map(opt => (
                  <MenuItem key={opt.ColorBookName} value={opt.ColorBookID} >{opt.ColorBookName}</MenuItem>
                ))}

              </Select>
            </FormControl>
          )}
      </Stack>
      <Stack direction={"row"} alignItems={"center"} mt={1} >
        <MLabel style={{ width: "65px" }}>色番号：</MLabel>
        <Autocomplete
          size="small"
          value={colorNo.selectedValue || {}}
          onChange={(event, newValue) => {
            setColorNo(preValue => ({
              ...preValue,
              selectedValue: { ...newValue }
            }));
          }}
          inputValue={inputColorNo}
          onInputChange={(event, newInputValue) => {
            if (newInputValue.length > 10) return;
            if (colorBook.selectedValue.ColorBookID === COLOR_BOOK_OTHERID) {
              setColorNo({
                selectedValue: { ColorNoID: 1, ColorNoName: newInputValue },
                options: []
              });
            }
            setInputColorNo(newInputValue);
          }}
          id="color-no"
          loading={loadingColorNo}
          disableClearable
          selectOnFocus
          freeSolo
          noOptionsText="色番号なし"
          isOptionEqualToValue={(option, value) => {
            return option.ColorNoID === value.ColorNoID;
          }}
          getOptionLabel={(option) => option.ColorLevelID > 1 ? `${option?.ColorNoName || ""}　${option?.ColorLevelName || ''}` : option?.ColorNoName || ""}
          options={colorNo.options || []}
          sx={{ width: 170, ml: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingColorNo ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}

            />
          )}
        />
      </Stack>
    </div>
  </>;
};

export default SelectColor;
