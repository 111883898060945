import React from "react";
import { Content, Line, MLabel, SHeader, Wrapper } from "./styles";
import dayjs from "dayjs";
import { DELIVERY_TIME_ORDER, SALESSLIP_STATUS } from "Common";
import ButtonBack from "components/ButtonBack";
import GoodsItem from "../GoodsItem";

const OrderDetails = ({ order, handleAddToCard, handleBack }) => {
  const orderTop = order?.length > 0 ? order[0] : null;
  return <Wrapper>
    {!!orderTop ? (
      <Content>
        <Line>
          <MLabel>現場：</MLabel>
          {orderTop.SiteName}
        </Line>
        <Line>
          <MLabel>住所：</MLabel>
          {`${orderTop.DeliveryTo || ''}${orderTop.DeliveryPref || ''}${orderTop.DeliveryCity || ''}${orderTop.DeliveryAdrNumber || ''}`}
        </Line>
        <Line>
          <MLabel>受注日：</MLabel>
          {orderTop.AcceptedDate || '―'}
        </Line>
        <Line>
          <MLabel>配達タイプ：</MLabel>
          {orderTop.DeliveryTypeID || '―'}
        </Line>
        <Line>
          <MLabel>配送日：</MLabel>
          {orderTop.DeliveryDate.length > 9 ? dayjs(orderTop.DeliveryDate).format("YYYY年MM月DD日") + DELIVERY_TIME_ORDER[orderTop?.DeliveryTimeID || 1] + SALESSLIP_STATUS[orderTop.SalesSlipStatusID || 1] : '-'}
        </Line>
        <Line>
          <MLabel>メモ：</MLabel>
          {orderTop.OrderTopMemo || ''}
        </Line>
        <SHeader>商品一覧</SHeader>
        {order.map(item => <GoodsItem key={`${item.SalesSlipID}${item.GoodsID}${item.GoodsSubID}`} item={item}
          handleAddToCard={() => { handleAddToCard(item) }} />)}
      </Content>
    ) : "該当データがなし"}
    <ButtonBack onHandleBack={() => { handleBack(); }} />
  </Wrapper >
};

export default OrderDetails;
