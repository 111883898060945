import { useEffect, useState } from "react"
import orderApi from "api/orderApi";

export const useOrder = () => {

    const [termSearch, setTermSearch] = useState({});
    const [orderList, setOrderList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [orderSelected, setOrderSelected] = useState(null); //{orderID: OrderID, slipID:SalesSlipID}
    const [objOrder, setObjOrder] = useState(null);


    useEffect(() => {
        (async function searchOrder() {
            try {
                setIsLoading(true);

                /**Call Api */
                const params = {
                    txtSearch: termSearch.txtSearch || "",
                    siteName: termSearch.siteName,
                    siteAddr: termSearch.siteAddr,
                    dFrom: termSearch.dFrom,
                    dTo: termSearch.dTo,
                }
                const dtResponse = await orderApi.searchOrder(params);
                const dtOrder = dtResponse?.data['dtOrder'] ?? [];
                setOrderList(dtOrder);
                setIsLoading(false);

            } catch (err) {
                alert(err);
                setIsLoading(false);
            }
        })();

    }, [termSearch])

    useEffect(() => {
        if (!orderSelected) return;
        (async function getOrder() {
            try {
                setIsLoading(true);
                /**Call Api */
                const dtResponse = await orderApi.getOrderDetails({ ...orderSelected });
                const dtOrder = dtResponse?.data['dtOrder'] ?? [];
                setObjOrder(dtOrder);
                setIsLoading(false);

            } catch (err) {
                alert(err);
                setIsLoading(false);
            }
        })();

    }, [orderSelected])

    return { termSearch, setTermSearch, orderList, objOrder, setObjOrder, setOrderSelected, isLoading };
}